import React from "react";
import ReactDOM from "react-dom";

import { CaseManager, APPS } from "@flow/case-manager";
import * as customerSetup from "./customer";
import * as serviceWorker from "./serviceWorker";
import translatesInbox from "./translate/inbox";
import "./index.css";

require("babel-core/register");
require("babel-polyfill");

const customTranslates = [
  {
    app: APPS.Inbox,
    language: "no",
    translates: translatesInbox
  }
];

const FlowPortalWithApps = (
  <CaseManager
    apps={[APPS.Inbox, APPS.Process, APPS.Decisions, APPS.Roles]}
    inboxConfiguration={{
      customComponents: customerSetup.CustomComponents,
      searchFields: customerSetup.SearchFields,
      startableProcesses: customerSetup.StartableProcesses
    }}
    loginConfiguration={{}}
    translates={customTranslates}
    language="no"
  />
);

ReactDOM.render(FlowPortalWithApps, document.getElementById("root"));

serviceWorker.unregister();
