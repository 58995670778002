import { Icons } from "@flow/icons";

const CustomComponents = {
  case: {
    caseSummary: undefined,
    caseDetails: undefined,
    tabs: {
      caseDetails: undefined
    },
    tasks: {
      useNewTasksModule: true
    },
    taskComponents: {}
  },
  queue: {
    queueItem: undefined
  },
  queueDefinition: {
    "all-cases": {
      name: "all-cases",
      icon: Icons.MenuAllCases,
      url:
        "/api/flow/flows?status=!completed&status=!archived&flowDefinitionId=!remove-expired-applications&flowDefinitionId=!credit-score-data-archival&flowDefinitionId=!credit-score-data-sync",
      countUrl:
        "flows/count?status=!completed&status=!archived&flowDefinitionId=!remove-expired-applications&flowDefinitionId=!credit-score-data-archival&flowDefinitionId=!credit-score-data-sync"
    },
    onboarding: {
      name: "onboarding",
      icon: Icons.Upload,
      url: "/api/flow/flows?status=!completed&status=!archived&flowDefinitionId=onboarding-tutorial",
      countUrl: "flows/count?status=!completed&status=!archived&flowDefinitionId=onboarding-tutorial"
    },
    "completed-cases": {
      name: "completed-cases",
      icon: Icons.CaseComplete,
      url:
        "/api/flow/flows?status=completed&status=!archived&flowDefinitionId=!remove-expired-applications&flowDefinitionId=!credit-score-data-archival&flowDefinitionId=!credit-score-data-sync",
      countUrl:
        "flows/count?status=completed&status=!archived&flowDefinitionId=!remove-expired-applications&flowDefinitionId=!credit-score-data-archival&flowDefinitionId=!credit-score-data-sync"
    },
    "archived-cases": {
      name: "archived-cases",
      icon: Icons.Trashcan,
      url:
        "/api/flow/flows?status=archived&flowDefinitionId=!remove-expired-applications&flowDefinitionId=!credit-score-data-archival&flowDefinitionId=!credit-score-data-sync",
      countUrl:
        "flows/count?status=archived&flowDefinitionId=!remove-expired-applications&flowDefinitionId=!credit-score-data-archival&flowDefinitionId=!credit-score-data-sync"
    }
  },
  chat: {
    enabled: false
  }
};

const StartableProcesses = ["hello-world", "onboarding-tutorial"];
const SearchFields = [];

export { CustomComponents, SearchFields, StartableProcesses };
